/**
 * Removes item from Array
 *
 * @param {Array} array
 * @param {Object} object
 */
export function removeItem(array, object) {
	var index = array.indexOf(object);
	if (index > -1) {
		array.splice(index, 1);
	}
	return array;
}

/**
 * Format a phone number to have dashes.
 * @param {String} phoneNumber
 * @returns
 */
export function formatPhoneNumber(phoneNumber) {
	var formattedPhoneNumber =
		phoneNumber.slice(0, 1) +
		'-' +
		phoneNumber.slice(1, 4) +
		'-' +
		phoneNumber.slice(4, 7) +
		'-' +
		phoneNumber.slice(7);
	return formattedPhoneNumber;
}

/**
 * Format a phone number to have parentheses.  This method only works with 10 digits numbers.
 * @param {String} phoneNumber
 * @returns
 */
export function formatPhoneNumberWithParentheses(phoneNumber) {
	if (phoneNumber) {
		const hasCountry = phoneNumber[0] === '1' && phoneNumber.length > 10;
		let returnVal = '';

		if (hasCountry) phoneNumber = phoneNumber.slice(1);
		if (phoneNumber.length === 10) {
			returnVal = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
		}
		return hasCountry ? `1 ${returnVal}` : returnVal;
	}
}

/**
 * Function to download a blob file
 * @param {file} blob - binary file
 * @param {string} filename - filename including file extension
 */
export function downloadBlob(blob, filename) {
	if (blob) {
		const blobUrl = URL.createObjectURL(blob);
		const link = document.createElement('a');

		link.href = blobUrl;
		link.download = filename;

		document.body.appendChild(link);

		link.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window
			})
		);

		document.body.removeChild(link);
	}
}
