<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<div>
		<BaseButton
			v-if="!contactInformationDisplayed && showToggle"
			:label="$t('button.callUs')"
			:pill="false"
			variant="link"
			:class="`pl-0 loginButton ${className}`"
			icon-position="right"
			:icon="iconDown"
			:automation-id="getAutomationId('call_us_open')"
			:data-tracking-id="getAutomationId('authentication-call_us_open')"
			@click="toggleContactInformation"
		/>

		<BaseButton
			v-if="contactInformationDisplayed && showToggle"
			:label="$t('button.callUs')"
			:pill="false"
			variant="link"
			class="pl-0 loginButton"
			icon-position="right"
			:icon="iconUp"
			:automation-id="getAutomationId('call_us_close')"
			:data-tracking-id="getAutomationId('call_us_close')"
			@click="toggleContactInformation"
		/>

		<template v-if="!loading && (contactInformationDisplayed || !showToggle)">
			<div class="contactInfo">
				<!-- eslint-disable-next-line -->
				<p class="h6 contactTitle">{{ contactInfo[locale].tollFreeText }}:</p>
				<PhoneNumberDisplay :phone-number="formattedPhoneNumber" />
			</div>

			<div v-if="showHoursOfOperationSection" class="mb-1">
				<span class="info">{{ contactInfo[locale].hrsOfOperation }}</span>
			</div>
			<div v-if="showHoursOfOperationRegionsSection" class="mb-1 mt-4">
				<div
					v-for="(hrsOfOperation, eKey) in contactInfo[locale].hrsOfOperationRegions"
					:key="eKey"
				>
					<h5 class="mb-0 pre-info">{{ hrsOfOperation.name }}</h5>
					<div v-if="eKey == contactInfo[locale].hrsOfOperationRegions.length - 1" class="info">
						{{ hrsOfOperation.value }}
					</div>
					<div v-else class="mb-4 info">{{ hrsOfOperation.value }}</div>
				</div>
			</div>
		</template>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component renders a button that toggles contact information
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseButton from '@/components/common/base/BaseButton';
import PhoneNumberDisplay from '@/components/common/PhoneNumberDisplay';
import IdMixin from '@/mixins/id.js';
import ContactInfo from '@/models/ContactInfo';
import { formatPhoneNumber } from '@/utils/commonUtils';

// @vue/component
@Component({
	name: 'CallUsInformation',
	components: {
		BaseButton,
		PhoneNumberDisplay
	},
	mixins: [IdMixin],
	props: {
		className: {
			type: String,
			default: null
		},
		showToggle: {
			type: Boolean,
			default: false
		},
		ontarioPhone: {
			type: String,
			default: null
		},
		quebecPhone: {
			type: String,
			default: null
		},
		atlanticPhone: {
			type: String,
			default: null
		},
		canadaPhone: {
			type: String,
			default: null
		},
		ontarioDescription: {
			type: String,
			default: null
		},
		quebecDescription: {
			type: String,
			default: null
		},
		atlanticDescription: {
			type: String,
			default: null
		},
		canadaDescription: {
			type: String,
			default: null
		},
		automationId: {
			type: String,
			default: ''
		}
	},
	watch: {
		locale() {
			this.getInfo();
		}
	}
})
export default class CallUsInformation extends Vue {
	contactInformationDisplayed = false;

	data() {
		return {
			iconDown: ['fal', 'angle-down'],
			iconUp: ['fal', 'angle-up'],
			contactInfo: []
		};
	}
	/**
	 * Handle input event.
	 * @param {string} value The input value.
	 */
	toggleContactInformation() {
		this.contactInformationDisplayed = !this.contactInformationDisplayed;
	}

	async created() {
		await this.getInfo();
	}

	get showHoursOfOperationSection() {
		return this.contactInfo[this.locale].hrsOfOperation;
	}

	get showHoursOfOperationRegionsSection() {
		return this.contactInfo[this.locale].hrsOfOperationRegions;
	}

	get formattedPhoneNumber() {
		return formatPhoneNumber(this.contactInfo[this.locale].tollFree);
	}

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get loading() {
		return this.$store.state.loading;
	}

	get brand() {
		// Get the brand from the URL
		let brand = this.$router?.currentRoute.query.brand;
		if (brand) brand = brand.toLowerCase(); // make sure lowercase

		if (brand === 'sbc' || brand === 'qbc' || brand === 'obc') {
			// if it's a valid brand show info
			return brand;
		} else {
			// if no brand hide info
			return 'mbc';
		}
	}

	async getInfo() {
		this.$store.dispatch('updateLoading', true);
		if (this.brand) {
			try {
				this.contactInfo[this.locale] = await ContactInfo.getBrandedContactUsInfo(
					this.brand,
					this.locale
				);
			} catch (err) {
				this.$emit('onError');
			}
		} else {
			this.$emit('onError');
		}
		this.$store.dispatch('updateLoading', false);
	}
}
</script>

<!-- *************************************************************************
	Styles
	************************************************************************* -->
<style lang="scss" scoped>
.contactInfo {
	clear: both;
	font-family: 'Lato', sans-serif;
}
.contactTitle {
	display: inline-block;
	font-size: 17px;
	padding-right: 5px;
	margin-bottom: 0;
}
.contactPhone {
	display: inline-block;
	font-size: 17px;
	color: #0079ad;
	margin-bottom: 0;
}
.contactDescription {
	clear: both;
	font-size: 15px;
	font-weight: 300;
}
</style>

<i18n>
{
	"en": {
		"button": {
            "callUs": "Call us"
        }
	},
	"fr": {
		"button": {
            "callUs": "Appelez-nous"
        }
	}
}
</i18n>
