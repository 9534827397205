<template>
	<a class="phoneNumber" :href="'tel:' + phoneNumber">{{ phoneNumber }}</a>
</template>

<script>
/**
 * This component displays the phone number in a specific style
 * if user hovers, the phone numbers are underlined and darken in color like other text links
 */
import Vue from 'vue';
import Component from 'vue-class-component';
// @vue/component
@Component({
	name: 'PhoneNumberDisplay',
	props: {
		phoneNumber: {
			type: String,
			default: null
		}
	}
})
export default class PhoneNumberDisplay extends Vue {}
</script>

<style lang="scss" scoped>
.phoneNumber {
	display: inline-block;
	font-size: $body-regular-bold-font-size;
	font-weight: $font-regular;
	color: $blue;
	margin-bottom: 0;
}
.phoneNumber:hover:not(.active) {
	background-color: $white;
	color: $dark-blue;
}
</style>
