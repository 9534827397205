<!-- eslint-disable vue/no-v-html -->
<!-- removed v-html error for entire page has some fo the designs that use this screen require bold content on certain words -->
<template>
	<div class="container-width">
		<FontAwesomeIcon
			v-if="iconVisible"
			:icon="[iconType, icon]"
			size="lg"
			class="my-3 text-center icon"
			:class="[iconColor, 'icon-size', 'w-100']"
		/>
		<h1 :class="`${subTitle ? 'mb-2' : 'mb-4'} text-center px-0 px-sm-4`" v-html="titleLabel"></h1>
		<p v-if="subTitle" class="text-center sub-title mb-4" v-html="subTitle" />
		<BaseCard v-if="hasBody">
			<p v-if="secondaryTitle" class="secondary-title" v-html="secondaryTitleLabel" />
			<div v-if="hasBody">
				<p v-if="contentCentered" class="content-centered" v-html="contentCentered" />
				<p
					v-if="content"
					:class="`content ${!secondaryContent && !isFailed && !$slots.default ? 'mb-0' : ''}`"
					v-html="contentLabel"
				/>
				<p v-if="secondaryContent" :class="secondaryContentClass" v-html="secondaryContent"></p>
				<CallUsInformation
					v-if="isFailed"
					class-name="pb-0"
					:automation-id="getAutomationId('result')"
				/>
				<slot></slot>
			</div>
			<p v-if="outOfCardContent" class="second-content-centered">
				{{ outOfCardContent }}
			</p>
		</BaseCard>
		<BaseButton
			v-if="!hideButton"
			:label="btnLabel"
			variant="link"
			:class="`${hasBody ? 'text-left' : 'text-center mt-0'} pl-0 pt-0`"
			:automation-id="getAutomationId('result')"
			@click="toLogin()"
		/>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import CallUsInformation from '@/components/common/CallUsInformation';
import { isNull } from '@/utils/string';
import * as Routes from '@/constants/Routes';

// @vue/component
@Component({
	name: 'Result',
	components: {
		BaseButton,
		BaseCard,
		CallUsInformation
	},
	mixins: [IdMixin],
	props: {
		iconType: {
			type: String,
			default: 'fal'
		},
		icon: {
			type: String,
			default: 'check-circle'
		},
		iconColor: {
			type: String,
			default: 'success-color'
		},
		iconVisible: {
			type: Boolean,
			default: true
		},
		isFailed: {
			type: Boolean,
			default: false
		},
		hasBody: {
			type: Boolean,
			default: true
		},
		title: {
			type: String,
			default: null
		},
		subTitle: {
			type: String,
			default: null
		},
		secondaryTitle: {
			type: String,
			default: null
		},
		content: {
			type: String,
			default: null
		},
		secondaryContent: {
			type: String,
			default: null
		},
		secondaryContentClass: {
			type: String,
			default: null
		},
		contentCentered: {
			type: String,
			default: null
		},
		outOfCardContent: {
			type: String,
			default: null
		},
		button: {
			type: String,
			default: null
		},
		hideButton: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class Result extends Vue {
	get titleLabel() {
		return this.title ? this.title : this.$t('title');
	}
	get secondaryTitleLabel() {
		return !isNull(this.secondaryTitle) ? this.secondaryTitle : this.$t('resetTitle');
	}
	get contentLabel() {
		return this.content ? this.content : this.$t('content');
	}
	get btnLabel() {
		return this.button ? this.button : this.$t('button');
	}
	toLogin() {
		this.$router.push({ name: Routes.LOGIN });
	}
}
</script>
<style lang="scss" scoped>
.container-width {
	max-width: 600px;
	margin: auto;
}
.title {
	font-size: 32px;
	color: $black-text;
}
.sub-title {
	font-size: 21px;
	font-weight: 300;
}
.bold {
	font-family: $josefin-font-family;
	font-weight: 400;
}
.content-centered {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 21px;
	text-align: center;
}
.second-content-centered {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 21px;
	text-align: center;
}
.secondary-title {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 21px;
}
.icon-size {
	height: 42px;
	width: 42px;
}
.success-color {
	& path {
		color: $success-icon-green;
	}
}
.error-color {
	& path {
		color: $error-icon-red;
	}
}
</style>
<i18n>
{
    "en": {
        "title": "Success",
        "resetTitle": "Your reset password link has been sent!",
        "content": "Check your email and click on the link provided to create your new password.",
        "button": "Go to login >"
    },
	"fr": {
        "title": "C'est fait!",
        "resetTitle": "Nous vous avons envoyé un lien pour réinitialiser votre mot de passe.",
        "content": "Vérifiez votre boîte de courriels et cliquez sur le lien pour accéder à votre compte une première fois. ",
        "button": "Aller à la page de connexion >"
    }
}
</i18n>
